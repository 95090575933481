/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import getParcel from './async.getParcel'
import getParcels from './async.getParcels'
import unallocateParcel from './async.unallocateParcel'
import addParcelStatus from './async.addParcelStatus'
import requestCompartment from './async.requestCompartment'
import specifyNewCompartment from './async.specifyNewCompartment'

export const initialState = {
  loading: false,
  error: undefined,
  cache: {},
  data: {
    parcels: [],
    metadata: {},
  },
  filters: {
    daysBack: 0,
    flow: undefined,
    parcelId: undefined,
    date: undefined,
    currentStatus: undefined,
    deviationCode: undefined,
    location: {
      street: undefined,
      zip: undefined,
      city: undefined,
    },
  },
  sorting: { orderBy: undefined, order: undefined },
  pagination: { page: 0, perPage: 20 },
}

export const parcelsSlice = createSlice({
  name: 'parcels',
  initialState,
  reducers: {
    resetFilters(state, action) {
      state.filters = { ...initialState.filters, ...action.payload }
    },
    updateFilters(state, action) {
      state.filters = {
        ...initialState.filters,
        ...state.filters,
        ...action.payload,
      }
    },
    setPagination(state, action) {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      }
    },
    setSorting(state, action) {
      state.sorting = {
        ...state.sorting,
        ...action.payload,
      }
    },
    cacheParcels(state, action) {
      action.payload.parcels.forEach((parcel) => {
        state.cache[parcel._id] = parcel
      })
    },
  },
  extraReducers(builder) {
    builder
      // getParcels
      .addCase(getParcels.pending, (state) => {
        state.loading = true
      })
      .addCase(getParcels.rejected, (state, action) => {
        if (action.error.name !== 'AbortError') {
          state.error = action.payload ?? action.error
        }
      })
      .addCase(getParcels.fulfilled, (state, action) => {
        state.error = undefined
        const parcels = []
        action.payload.parcels.forEach((parcel) => {
          parcel.$cached = new Date().getTime()
          state.cache[parcel._id] = parcel
          parcels.push(state.cache[parcel._id])
        })
        state.data = {
          ...action.payload,
          parcels,
        }
      })

      // getParcel
      .addCase(getParcel.fulfilled, (state, action) => {
        const { parcel } = action.payload
        state.error = undefined
        parcel.$cached = new Date().getTime()
        state.cache[parcel._id] = parcel
      })

      // unallocateParcel
      .addCase(unallocateParcel.fulfilled, (state, action) => {
        const parcel = action.payload.updatedParcel
        parcel.$cached = new Date().getTime()
        state.cache[parcel._id] = parcel
      })

      // addParcelStatus
      .addCase(addParcelStatus.fulfilled, (state, action) => {
        action.payload.updatedParcels.forEach((parcel) => {
          parcel.$cached = new Date().getTime()
          state.cache[parcel._id] = parcel
        })
      })

      // Matchers
      // getParcels
      .addMatcher(getParcels.settled, (state) => {
        state.loading = false
      })
  },
})

// Action creators are generated for each case reducer function
export const {
  resetFilters,
  updateFilters,
  setPagination,
  setSorting,
  cacheParcels,
} = parcelsSlice.actions

export {
  getParcel,
  getParcels,
  unallocateParcel,
  addParcelStatus,
  requestCompartment,
  specifyNewCompartment,
}

export default parcelsSlice.reducer
