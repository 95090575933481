// import swal from 'sweetalert2'
import {
  carrierIsAllowedTo,
  isSuperUser,
  userIsAllowedTo,
} from '../utils/permissions'

const defaultState = {
  legacyView: localStorage.getItem('legacyView') !== 'false',
}

export default function root(state, action) {
  const newState = { ...(state || defaultState) }

  // console.log(action)

  const [actionName, resultType] = action.type.split('_')
  if (resultType === 'FAILED') {
    return newState
  }

  switch (actionName) {
    case 'getBoxTypes': {
      newState.boxTypes = action.payload.boxTypes
      break
    }
    case 'getMe': {
      newState.user = action.payload.user
      newState.carrier = action.payload.carrier
      if (
        !isSuperUser(newState.user) &&
        (!carrierIsAllowedTo(newState.carrier, 'adminBetaMode') ||
          !userIsAllowedTo(newState.user, 'adminBetaMode'))
      ) {
        localStorage.removeItem('legacyView')
        newState.legacyView = true
      }
      break
    }
    case 'getCarrier': {
      newState.carrier = action.payload
      if (
        !isSuperUser(newState.user) &&
        (!carrierIsAllowedTo(newState.carrier, 'adminBetaMode') ||
          !userIsAllowedTo(newState.user, 'adminBetaMode'))
      ) {
        localStorage.removeItem('legacyView')
        newState.legacyView = true
      }
      break
    }

    case 'getDrivers': {
      newState.drivers = action.payload
      break
    }

    case 'createDriver': {
      newState.drivers = newState.drivers.concat(action.payload.driver)
      break
    }

    case 'updateDriver': {
      newState.drivers = newState.drivers.map((d) => {
        if (d._id === action.payload.driver._id) return action.payload.driver

        return d
      })
      break
    }

    case 'deleteDriver': {
      newState.drivers = newState.drivers.filter(
        (d) => d._id !== action.payload.deletedId
      )
      break
    }

    case 'parcelFlow': {
      newState.parcelFlow = action.payload
      break
    }

    case 'setLegacyView': {
      newState.legacyView = action.payload
      localStorage.setItem('legacyView', newState.legacyView)
      break
    }

    case 'setShowModal': {
      newState.showModal = action.payload.modal
      newState.selectedParcel = action.payload.selectedParcel
      newState.selectedLocation = action.payload.selectedLocation
      break
    }

    case 'carrierHeader': {
      newState.carrierHeader = action.payload
      break
    }

    default:
  }

  return newState
}
