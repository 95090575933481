import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import * as Sentry from '@sentry/react'
import { Paper, Grid, Box, Button, Select, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import RegisteredAllocatedParcelsView from './RegisteredAllocatedParcelsView'
import CreateParcelModal from '../common/CreateParcel/CreateParcelModal'
import MessageSettingsModal from '../common/MessageSettingsModal'
import ExportModal from '../common/ExportModal'
import DriversSettingsModal from '../common/DriversSettingsModal'
import ChangePasswordModal from '../common/ChangePasswordModal'
import LocationsModal from '../common/Locations/LocationsModal'
import HandleParcelModal from '../common/HandleParcelModal'
import ParcelTraceLogsModal from '../common/ParcelTraceLogsModal'
import UsersModal from './modals/UsersModal/Modal'
import LocationModal from './modals/LocationModal/Modal'
import { getMe, setLegacyView } from '../redux/actions'
import { updateFilters } from '../redux/parcels/slice.parcels'
import { getCarriers } from '../redux/carriers/slice.carriers'
import iBoxenLogo from '../iboxen_logo.png'
import { theme } from '../theme'
import { useMe } from '../hooks/useMe'
import LanguageSelector from '../common/LanguageSelector'

export default function Home() {
  const dispatch = useDispatch()

  const [activePane] = useState(null)
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const closeModal = () => dispatch({ type: 'setShowModal', payload: {} })
  const setShowModal = (modal) =>
    dispatch({ type: 'setShowModal', payload: { modal } })
  const parcelFlow = useSelector((state) => state.parcels.filters.flow)

  const history = useHistory()

  const { t } = useTranslation('carriers')
  const carriers = useSelector((state) => state.carriers)
  const {
    legacyView,
    showModal,
    selectedParcel,
    selectedLocation,
    carrierHeader,
  } = useSelector((state) => state.root)
  const { user, carrier } = useMe()

  const renderActivePane = () => {
    switch (activePane) {
      case 'new parcels':
      default:
        return <RegisteredAllocatedParcelsView isModalOpen={!!showModal} />
    }
  }

  useEffect(() => {
    if (user.isSuperUser && !carriers.loading && !carriers.data.carriers.length)
      dispatch(getCarriers())
  }, [dispatch, user, carriers])

  useEffect(() => {
    dispatch(getMe())
  }, [dispatch, carrierHeader])

  useEffect(() => {
    if (!user || !carrier) return

    if (process.env.NODE_ENV === 'production') {
      window.Intercom('boot', { app_id: 'qb0qj775', name: user.username })
      Sentry.setContext('carrier', {
        _id: carrier._id,
        username: carrier.username,
        name: carrier.name,
      })
      Sentry.setContext('user', {
        _id: user._id,
        username: user.username,
        role: user.role?.niceName,
      })
    }

    if (carrier.shouldSetPassword === true) {
      setShowChangePasswordModal({ requiresOldPassword: false })
    }
  }, [user, carrier, dispatch])

  useEffect(() => {
    if (!user || !carrier) return

    const savedParcelFlow = localStorage.getItem('parcelFlow')
    if (legacyView) {
      const newFlow =
        !['pickup', 'delivery'].includes(savedParcelFlow) ||
        !carrier.isAllowedTo('usePickups') ||
        user.isAllowedTo('usePickups')
          ? 'delivery'
          : savedParcelFlow
      dispatch(updateFilters({ flow: newFlow }))
    }
  }, [user, carrier, dispatch, legacyView])

  const logout = () => {
    if (window.Intercom) window.Intercom('shutdown')

    localStorage.removeItem('jwt')
    history.push('/login')
  }

  return (
    <Container
      fluid
      style={{ paddingLeft: 15, paddingRight: 15, marginTop: 8 }}
    >
      {showChangePasswordModal && (
        <ChangePasswordModal
          requiresOldPassword={showChangePasswordModal.requiresOldPassword}
          onClose={() => setShowChangePasswordModal(false)}
        />
      )}
      {showModal === 'create-parcel' && (
        <CreateParcelModal closeModal={closeModal} />
      )}
      {showModal === 'message-settings' && (
        <MessageSettingsModal closeModal={closeModal} />
      )}
      {showModal === 'export' && (
        <ExportModal carrier={carrier} closeModal={closeModal} />
      )}
      {showModal === 'driver-settings' && (
        <DriversSettingsModal closeModal={closeModal} />
      )}
      {showModal === 'locations-settings' && (
        <LocationsModal closeModal={closeModal} />
      )}
      {showModal === 'parcel' ? (
        <HandleParcelModal closeModal={closeModal} parcelId={selectedParcel} />
      ) : null}
      {showModal === 'location' ? (
        <>
          <LocationModal
            onClose={closeModal}
            locationId={selectedLocation}
            parcelId={selectedParcel}
          />
        </>
      ) : null}
      {showModal === 'traceLogs' ? (
        <ParcelTraceLogsModal
          closeModal={closeModal}
          parcelId={selectedParcel}
        />
      ) : null}
      {showModal === 'manage-users' ? (
        <UsersModal
          onClose={(event, reason) => {
            if (reason && reason === 'backdropClick') return
            setShowModal(null)
          }}
        />
      ) : null}
      {showModal === 'drivers-settings' ? (
        <DriversSettingsModal closeModal={closeModal} />
      ) : null}

      <Paper sx={{ bgcolor: theme(parcelFlow).primary, mb: 1.5 }}>
        <Box sx={{ p: 1 }}>
          <Grid container spacing={1}>
            <Grid item sm={1.84} sx={{ textAlign: 'center' }}>
              <img
                alt="iBoxen Logo"
                src={iBoxenLogo}
                style={{ width: 128, maxWidth: '100%', paddingBottom: 5 }}
              />
              {!carriers.loading &&
              carriers.data.carriers.length &&
              user.isSuperUser ? (
                <Box>
                  <Select
                    size="small"
                    value={carrierHeader || carrier._id}
                    sx={{ fontSize: '0.75rem', width: 128, maxWidth: '100%' }}
                    onChange={(e) =>
                      dispatch({
                        type: 'carrierHeader',
                        payload: e.target.value,
                      })
                    }
                  >
                    {carriers.data.carriers
                      .slice()
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((c) => (
                        <MenuItem
                          key={c._id}
                          value={c._id}
                          sx={{ fontSize: '0.75rem' }}
                        >
                          {c.name}
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
              ) : (
                <h2
                  className="font-weight-bold"
                  style={{ marginTop: 10, marginBottom: 0, fontSize: '20px' }}
                >
                  {carrier?.name}
                </h2>
              )}
            </Grid>
            <Grid item sm={2}>
              {user.isAllowedTo('createParcels') && (
                <Button
                  color="iboxen"
                  onClick={() => setShowModal('create-parcel')}
                  sx={{
                    bgcolor: 'white',
                    ':hover': { bgcolor: 'grey.200' },
                    mb: 1,
                  }}
                  fullWidth
                >
                  {t('registerParcel')}
                </Button>
              )}
              {user.isAllowedTo('manageUsers') && (
                <Button
                  color="iboxen"
                  onClick={() => setShowModal('manage-users')}
                  sx={{
                    bgcolor: 'white',
                    ':hover': { bgcolor: 'grey.200' },
                  }}
                  fullWidth
                >
                  {t('users')}
                </Button>
              )}
            </Grid>
            <Grid item sm={1.84}>
              {user.isAllowedTo('updateSetting:message') && (
                <Button
                  color="iboxen"
                  onClick={() => setShowModal('message-settings')}
                  sx={{
                    bgcolor: 'white',
                    ':hover': { bgcolor: 'grey.200' },
                    mb: 1,
                  }}
                  fullWidth
                >
                  {t('messages')}
                </Button>
              )}
              {(user.isSuperUser ||
                user.isAllowedTo('adminBetaMode') ||
                carrier.isAllowedTo('adminBetaMode')) && (
                <Button
                  color="iboxen"
                  onClick={() => dispatch(setLegacyView(!legacyView))}
                  sx={{
                    bgcolor: 'white',
                    ':hover': { bgcolor: 'grey.200' },
                  }}
                  fullWidth
                >
                  {!legacyView
                    ? t('dateView', { ns: 'common' })
                    : t('filterView', { ns: 'common' })}
                </Button>
              )}
            </Grid>
            <Grid item sm={1.84}>
              {user.isAllowedTo('editDrivers') && (
                <Button
                  color="iboxen"
                  onClick={() => setShowModal('driver-settings')}
                  sx={{
                    bgcolor: 'white',
                    ':hover': { bgcolor: 'grey.200' },
                    mb: 1,
                  }}
                  fullWidth
                >
                  {t('drivers')}
                </Button>
              )}
              {legacyView && carrier.isAllowedTo('usePickups') && (
                <Button
                  color="iboxen"
                  onClick={() => {
                    const newParcelFlow =
                      parcelFlow === 'delivery' ? 'pickup' : 'delivery'
                    dispatch(updateFilters({ flow: newParcelFlow }))
                    localStorage.setItem('parcelFlow', newParcelFlow)
                  }}
                  sx={{
                    bgcolor: 'white',
                    ':hover': { bgcolor: 'grey.200' },
                  }}
                  fullWidth
                >
                  {parcelFlow === 'delivery'
                    ? t('showReturns')
                    : t('showDeliveries')}
                </Button>
              )}
            </Grid>
            <Grid item sm={1.84}>
              {user.isAllowedTo('export') && (
                <Button
                  color="iboxen"
                  onClick={() => setShowModal('export')}
                  sx={{
                    bgcolor: 'white',
                    ':hover': { bgcolor: 'grey.200' },
                    mb: 1,
                  }}
                  fullWidth
                >
                  {t('export')}
                </Button>
              )}
              {carrier.isAllowedTo('restrictLocations') &&
                user.isAllowedTo('restrictLocations') && (
                  <Button
                    color="iboxen"
                    onClick={() => setShowModal('locations-settings')}
                    sx={{
                      bgcolor: 'white',
                      ':hover': { bgcolor: 'grey.200' },
                    }}
                    fullWidth
                  >
                    {t('locations')}
                  </Button>
                )}
            </Grid>
            <Grid item sm={1.84}>
              <Button
                color="iboxen"
                onClick={logout}
                sx={{
                  bgcolor: 'white',
                  ':hover': { bgcolor: 'grey.200' },
                  mb: 1,
                }}
                fullWidth
              >
                {t('logout')}
              </Button>
              <Button
                color="iboxen"
                onClick={() => setShowChangePasswordModal(true)}
                sx={{
                  bgcolor: 'white',
                  ':hover': { bgcolor: 'grey.200' },
                }}
                fullWidth
              >
                {t('changePassword')}
              </Button>
            </Grid>
            <Grid item sm={0.8}>
              <Box
                alignItems="center"
                justifyContent="flex-end"
                display="flex"
                marginTop="3px"
              >
                <LanguageSelector />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      {renderActivePane()}
    </Container>
  )
}
