import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  TextField,
  InputAdornment,
  Checkbox,
  Button,
  IconButton,
  Grid,
  MenuItem,
  ListItemText,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Clear from '@mui/icons-material/Clear'
import { useTranslation } from 'react-i18next'
import { resetFilters, updateFilters } from '../redux/parcels/slice.parcels'
import DateFilter from '../shared/DateFilter'
import { useMe } from '../hooks/useMe'

const statuses = [
  'created',
  'on-way',
  'in-box',
  'marked-for-return',
  'deviation',
  'collected',
  'driver-picked-up',
  'cancelled',
  'pickup-on-way',
  'pickup-in-box',
  'pickup-ready-to-collect',
  'pickup-collected',
]
const deviationCodes = [
  'CustomReason',
  'FailToOpenLock',
  'FailToCloseLock',
  'NoParcelInLocker',
  'ParcelTooBig',
]

export default function ParcelFilters({ spacing, onSubmit }) {
  const dispatch = useDispatch()
  const { loading, filters } = useSelector((state) => state.parcels)
  const { carrier, user } = useMe()

  const { t } = useTranslation('parcels')
  const flows = useMemo(() => {
    const f = ['delivery']
    if (carrier.isAllowedTo('usePickups') || user.isAllowedTo('usePickups')) {
      f.push('pickup')
    }
    return f
  }, [carrier, user])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      if (typeof onSubmit === 'function') {
        onSubmit(e)
      }
    },
    [onSubmit]
  )

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={spacing}>
        <Grid item xs={3}>
          <TextField
            label={t('parcelId')}
            onChange={(e) =>
              dispatch(updateFilters({ parcelId: e.target.value }))
            }
            value={filters.parcelId || ''}
            fullWidth
            InputProps={{
              endAdornment: filters.parcelId && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      dispatch(updateFilters({ parcelId: undefined }))
                    }
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            size="small"
            sx={{ mb: spacing }}
          />
          <TextField
            label={t('type')}
            onChange={(e) => dispatch(updateFilters({ flow: e.target.value }))}
            value={filters.flow || ''}
            fullWidth
            select
            InputProps={{
              endAdornment: filters.flow && (
                <InputAdornment position="end" sx={{ mr: 1 }}>
                  <IconButton
                    onClick={() => dispatch(updateFilters({ flow: undefined }))}
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            size="small"
            sx={{
              mb: spacing,
              visibility: flows.length < 2 ? 'hidden' : 'visible',
            }}
          >
            <MenuItem>
              <em>-- none --</em>
            </MenuItem>
            {flows.map((value) => (
              <MenuItem key={value} value={value}>
                {t(`flows.${value}`)}
              </MenuItem>
            ))}
          </TextField>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            loading={loading}
          >
            <span>{t('search')}</span>
          </LoadingButton>
        </Grid>

        <Grid item xs={3}>
          <DateFilter
            label={t('createdDate')}
            onChange={(created) => dispatch(updateFilters({ created }))}
            value={filters.created}
            fullWidth
            size="small"
            InputProps={{
              endAdornment: (filters.created?.fromDate ||
                filters.created?.value) && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation()
                      dispatch(updateFilters({ created: undefined }))
                    }}
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ mb: spacing }}
          />
          <DateFilter
            label={t('statusDate')}
            onChange={(currentStatusCreated) =>
              dispatch(updateFilters({ currentStatusCreated }))
            }
            value={filters.currentStatusCreated}
            fullWidth
            size="small"
            InputProps={{
              endAdornment: (filters.currentStatusCreated?.fromDate ||
                filters.currentStatusCreated?.value) && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation()
                      dispatch(
                        updateFilters({ currentStatusCreated: undefined })
                      )
                    }}
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ mb: spacing }}
          />
          <Button variant="outlined" onClick={() => dispatch(resetFilters())}>
            {t('clear')}
          </Button>
        </Grid>

        <Grid item xs={3}>
          <TextField
            label={t('status')}
            onChange={(e) =>
              dispatch(
                updateFilters({
                  currentStatus:
                    e.target.value.length > 0 ? e.target.value : undefined,
                })
              )
            }
            value={filters.currentStatus || []}
            fullWidth
            select
            InputProps={{
              autoComplete: 'off',
            }}
            SelectProps={{
              multiple: true,
              renderValue: (selected) =>
                selected.map((s) => t(`statuses.${s}`)).join(', '),
              endAdornment: filters.currentStatus && (
                <InputAdornment position="end" sx={{ mr: 1 }}>
                  <IconButton
                    onClick={() =>
                      dispatch(updateFilters({ currentStatus: undefined }))
                    }
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            size="small"
            sx={{ mb: spacing }}
          >
            {statuses.map((value) => (
              <MenuItem key={value} value={value} dense>
                <Checkbox
                  checked={(filters.currentStatus || []).includes(value)}
                />
                <ListItemText primary={t(`statuses.${value}`)} key={value} />
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label={t('deviationCode')}
            onChange={(e) =>
              dispatch(updateFilters({ deviationCode: e.target.value }))
            }
            value={filters.deviationCode || ''}
            fullWidth
            select
            size="small"
            InputProps={{
              endAdornment: filters.deviationCode && (
                <InputAdornment position="end" sx={{ mr: 1 }}>
                  <IconButton
                    onClick={() =>
                      dispatch(updateFilters({ deviationCode: undefined }))
                    }
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ mb: spacing }}
          >
            <MenuItem>
              <em>-- none --</em>
            </MenuItem>
            {deviationCodes.map((value) => (
              <MenuItem key={value} value={value}>
                {t(`deviationCodes.${value}`)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={t('locationAddress')}
            onChange={(e) =>
              dispatch(
                updateFilters({
                  location: { ...filters.location, street: e.target.value },
                })
              )
            }
            value={filters.location.street || ''}
            fullWidth
            InputProps={{
              endAdornment: filters.location.street && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      dispatch(
                        updateFilters({
                          location: {
                            ...filters.location,
                            street: undefined,
                          },
                        })
                      )
                    }
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            size="small"
            sx={{ mb: spacing }}
          />
          <TextField
            label={t('locationZip')}
            onChange={(e) =>
              dispatch(
                updateFilters({
                  location: {
                    ...filters.location,
                    zip: e.target.value.replace(/[^\d]/g, ''),
                  },
                })
              )
            }
            value={filters.location.zip || ''}
            fullWidth
            InputProps={{
              endAdornment: filters.location.zip && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      dispatch(
                        updateFilters({
                          location: { ...filters.location, zip: undefined },
                        })
                      )
                    }
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            size="small"
            sx={{ mb: spacing }}
          />
          <TextField
            label={t('locationCity')}
            onChange={(e) =>
              dispatch(
                updateFilters({
                  location: { ...filters.location, city: e.target.value },
                })
              )
            }
            value={filters.location.city || ''}
            fullWidth
            InputProps={{
              endAdornment: filters.location.city && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      dispatch(
                        updateFilters({
                          location: { ...filters.location, city: undefined },
                        })
                      )
                    }
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            size="small"
          />
        </Grid>
      </Grid>
    </form>
  )
}
